import { useEffect } from "react";
import TagManager from "react-gtm-module";

function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-5DG4KNX",
    });

    const run = async () => {
      if (typeof window !== "undefined") {
        const HREF = decodeURIComponent(decodeURI(window.location.href).replace(/\+/g, " "));
        const query = HREF.split("?")[1];
        if (!!query) {
          const body = new URLSearchParams();
          const queries = query.split("&");
          let failed = false;
          let dataLayerFrom = "";

          queries.forEach((q) => {
            const _q = q.split("=");
            if (!_q[1]) {
              failed = true;
            }

            if (_q[0] === "dataLayerFrom") {
              dataLayerFrom = _q[1];
            } else {
              body.append(_q[0], _q[1]);
            }
          });

          if (failed) {
            window.alert("내용을 입력해주세요.");
            window.self.close();
            return;
          }

          try {
            await subscribe(body);

            TagManager.dataLayer({
              dataLayer: {
                event: "newsletter_subscribe",
                formLocation: dataLayerFrom,
              },
            });

            window.alert("구독신청이 완료되었어요.");
          } catch (e) {
            window.alert("구독신청이 실패했습니다.");
          }

          // window.self.close();
        }
      }
    };

    run();
  }, []);

  const subscribe = (body) => {
    const url = new URL(`${process.env.REACT_APP_API_HOST}/api/v2/newsletter/members`);

    return fetch(url, {
      credentials: "include",
      body,
      method: "POST",
    }).then((res) => res.json());
  };
  return <div></div>;
}

export default App;
